<template>
  <div>
    <div class="page-title">
      <h3>Добавление заявки</h3>
      <span>
        <a class="btn-floating btn-large waves-effect waves-light red"
           v-tooltip="'Вернуться'"
           @click="$router.push('/tasks')">
          <i class="material-icons">arrow_back</i>
        </a>
      </span>
    </div>

    <form class="form" @submit.prevent="submitHandler">
      <div class="row">
        <div class="input-field">
          <input id="external_id" type="text" v-model="external_id">
          <label for="external_id">Внешний ID</label>
        </div>
      </div>

      <div class="row">
        <div class="input-field">
          <input id="object" type="text" v-model="object_input" ref="object_autocomplete" :class="{invalid: $v.object_id.$error}" autocomplete="off"
                 @input="findObject">
          <label for="object">Объект</label>
          <small class="helper-text invalid" v-if="$v.object_id.$error">Не выбран объект</small>
        </div>
      </div>

      <div class="row">
        <div class="input-field">
          <section v-if="team_id || !isAdmin">
            <input id="department_id" type="text" v-model="department_name" disabled>
            <label for="department_id">Отдел</label>
          </section>
          <section v-else>
            <select id="department_id_select" ref="department_select" v-model="department_id"
                    @change="changeDepartment" :class="{invalid: $v.department_id.$error}">
              <option value="" selected>Нет</option>
              <option v-for="r in department_list" :key="r.id" :value="r.id">{{r.name}}
              </option>
            </select>
            <label for="department_id_select">Отдел</label>
            <small class="helper-text invalid" v-if="$v.department_id.$error">Выерите отдел</small>
          </section>
        </div>
      </div>

      <div class="row">
        <div class="input-field">
          <select id="team_id" ref="team_select" v-model="team_id" @change="changeTeam">
            <option value="" selected>Нет</option>
            <option v-for="r in team_list.teams" :key="r.id" :value="r.id">{{r.name}}</option>
          </select>
          <label for="team_id">Бригада</label>
        </div>
      </div>

      <div class="row">
        <div class="input-field">
          <select id="project_id" ref="project_select" v-model="project_id">
            <option value="" selected>Нет</option>
            <option v-for="r in project_list" :key="r.id" :value="r.id">{{r.name}}</option>
          </select>
          <label for="project_id">Проект</label>
        </div>
      </div>

      <div class="row">
        <div class="input-field">
          <select id="type" ref="type_select" v-model="type" :class="{invalid: $v.type.$error}">
            <option value="" disabled selected>Выбрать</option>
            <option v-for="r in type_list" :key="r" :value="r">{{r}}</option>
          </select>
          <label for="type">Тип</label>
          <small class="helper-text invalid" v-if="$v.type.$error">Выерите тип заявки</small>
        </div>
      </div>

      <div class="row">
        <div class="input-field">
          <input id="title" type="text" v-model="title" :class="{invalid: $v.title.$error}">
          <label for="title">Название</label>
          <small class="helper-text invalid" v-if="$v.title.$error">Не указано название</small>
        </div>
      </div>

      <div class="row">
        <div class="input-field">
          <textarea id="description" class="materialize-textarea" v-model="description"></textarea>
          <label for="description">Описание</label>
        </div>
      </div>

      <div class="row">
        <div class="input-field">
          <textarea id="note" class="materialize-textarea" v-model="note"></textarea>
          <label for="note">Примечание</label>
        </div>
      </div>

      <div class="row">
        <div class="input-field">
          <input id="planned_end_time" type="text" v-model.lazy="planned_end_time" autocomplete="off"
                 class="datepicker" v-datePicker="planned_end_time" :class="{invalid: $v.planned_end_time.$error}">
          <label for="planned_end_time">План. дата завершения</label>
          <small class="helper-text invalid" v-if="$v.planned_end_time.$error">Не указана запланированная дата выполнения</small>
        </div>
      </div>

      <button class="btn waves-effect waves-light blue darken-4" type="submit">
        Сохранить
        <i class="material-icons right">send</i>
      </button>
    </form>
  </div>
</template>

<script>
  import {required} from 'vuelidate/lib/validators'
  import convertDateString from '../../utils/date'

  export default {
    metaInfo() {
      return {
        title: 'Редактирование заявки'
      }
    },
    data: () => ({
      isAdmin: false,
      loading: true,
      department_select: null,
      team_select: null,
      project_select: null,
      object_autocomplete: null,
      object_autocomplete_limit: 20,
      department_list: [],
      team_list: [],
      project_list: [],
      type_list: ['ППР', 'КТО'],
      external_id: '',
      object_id: '',
      object_input: '',
      department_id: '',
      department_name: '',
      team_id: '',
      project_id: '',
      title: '',
      description: '',
      note: '',
      planned_end_time: null,
      type: '',
    }),
    validations: {
      object_id: {required},
      department_id: {required},
      type: {required},
      title: {required},
      planned_end_time: {required},
    },
    async mounted() {
      this.isAdmin = await this.$store.dispatch('profile/isAdmin')
      const departments = await this.$store.dispatch('fetchDepartments')
      this.department_list = departments.filter(event => event.id > 1)
      this.project_list = await this.$store.dispatch('fetchProjects')

      if (!this.isAdmin) {
        this.department_id = await this.$store.dispatch('profile/getDepartmentId')
        this.team_list = await this.$store.dispatch('fetchTeams', {})

        this.department_list.forEach(department => {
          if (department.id === this.department_id) {
            this.department_name = department.name
          }
        })
      } else {
        this.team_list = await this.$store.dispatch('fetchAllTeams')
      }

      this.loading = false

      setTimeout(() => {
        this.department_select = M.FormSelect.init(this.$refs.department_select)
        this.team_select = M.FormSelect.init(this.$refs.team_select)
        this.project_select = M.FormSelect.init(this.$refs.project_select)
        this.type_select = M.FormSelect.init(this.$refs.type_select)
        M.updateTextFields()

        this.object_autocomplete = M.Autocomplete.init(this.$refs.object_autocomplete, {
          data: {},
          limit: this.object_autocomplete_limit,
          onAutocomplete: this.setFoundObject,
        })
      })
    },
    methods: {
      async submitHandler() {
        if (this.$v.$invalid) {
          this.$v.$touch()
          return
        }

        const uid = await this.$store.dispatch('profile/getUid')
        const task = {
          external_id: this.external_id,
          object: {"id": this.object_id},
          department: this.department_id ? {"id": this.department_id} : null,
          engineer: {"id": uid},
          team: this.team_id ? {"id": this.team_id} : null,
          type: this.type,
          title: this.title,
          description: this.description,
          note: this.note,
          planned_end_time: this.planned_end_time ? {seconds: convertDateString(this.planned_end_time + ' 23:59:59').getTime() / 1000} : null,
        }

        const t = await this.$store.dispatch('createTask', task)
        await this.$router.push('/tasks/' + t.id)
      },
      async findObject() {
        const object_list = await this.$store.dispatch('findObject', this.object_input, this.object_autocomplete_limit)
        this.object_autocomplete.updateData(object_list)
      },
      async setFoundObject(value) {
        const id = value.match(/GSI([0-9]{1,10})/)
        if (id.length === 2) {
          this.object_id = parseInt(id[1])
        }
      },
      changeTeam() {
        if (this.department_select && this.department_select.destroy) {
          this.department_select.destroy()
        }

        setTimeout(() => {
          this.department_select = M.FormSelect.init(this.$refs.department_select)
        })
      },
      async changeDepartment() {
        this.department_list.forEach(department => {
          if (department.id === this.department_id) {
            this.department_name = department.name
          }
        })

        this.team_list = await this.$store.dispatch('fetchTeams', {departmentId: this.department_id})

        if (this.team_select && this.team_select.destroy) {
          this.team_select.destroy()
        }

        setTimeout(() => {
          this.team_select = M.FormSelect.init(this.$refs.team_select)
        })
      },
    },
    destroyed() {
      if (this.department_select && this.department_select.destroy) {
        this.department_select.destroy()
      }
      if (this.team_select && this.team_select.destroy) {
        this.team_select.destroy()
      }
      if (this.project_select && this.project_select.destroy) {
        this.project_select.destroy()
      }
      if (this.type_select && this.type_select.destroy) {
        this.type_select.destroy()
      }
    }
  }
</script>

<style scoped>
  .switch {
    margin-top: 1rem;
  }
</style>
